import React, { Suspense, useRef, useEffect, useState } from "react";
import PropTypes, { array } from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Loader from "components/Loader/Loader.jsx";
import WhatsApp from "components/WhatsApp/WhatsApp.jsx";
import TimeLine from "components/Timeline/Timeline.jsx";


import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import ViewDisposition from "../Forms/ViewDisposition";
import ViewTag from "../Forms/ViewTags";
import EditProspect from "./EditProspect";
import LeadScorePopup from "./LeadScorePopup";
import MoreIcon from '@material-ui/icons/More';
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import prospectdup from "assets/img/prospectdup.png";

import { API_GET, API_POST } from "../../services/api.service";
import _ from "underscore";
import { Badge, Box, ButtonGroup, Grid, IconButton } from "@material-ui/core";
import ProspectAssignment from "./ProspectAssignment";
import CRMView from "../CRMView/CRMView";
import { formatFieldValue, getCookie, getToken, maskNumber, setCookie, cacheUtil } from "../../services/Common"
import { CreateBody, sendCommByTemplate, replaceAndReadyAPI } from "../../services/CommonServices";
import TemplatePopup from "../CommTemplates/TemplatePopup";
import axios from "axios";
import { MoreVertSharp } from "@material-ui/icons";
import InfoBox from "../../components/InfoIcon/InfoIcon";




const style = {
  disposition: {
    display: "inline-block",
    width: "100%",
  },
  dispositionActions: {
    float: "right",
  },
};

// const onSelectDisposition = async (item) => {
//   console.log("Disposition", item);
// }
// const onRemarksChange = async (item) => {
//   console.log("onRemarksChange", item);
// }
// const onChangeCallback = async (item) => {
//   console.log("onChangeCallback", item);
// }
// const onChangeAppointment = async (item) => {
//   console.log("onChangeAppointment", item);
// }
// }


function ViewProspect(props) {



  const { user, history } = props;



  const [customFields, setCustomFields] = useState([]);
  const [repeatFilter, setRepeatFilter] = useState([]);
  const [prospects, setProspects] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [baseProspects, setBaseProspects] = useState(null);
  const [dispositions, setDispositions] = useState([]);
  const [dispositionsAll, setDispositionsAll] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [ScheduleHistory, setScheduleHistory] = useState([]);
  const [AssignmentHistory, setAssignmentHistory] = useState([]);
  const [prospectSource, setprospectSource] = useState([]);
  const [CallDetails, setCallDetails] = useState([]);
  const [calculatedScore, setCalculatedScore] = useState(null);
  const [campaigns, setCampaigns] = useState(null);
  const [, setCampaignFields] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [notify, setNotify] = useState({
    open: false,
    message: null,
    color: "success",
  });
  const [IsAssignment, setIsAssignment] = useState(false);
  const [campaignData, setCampaignnData] = useState([]);

  const [autoCalling, setautoCalling] = useState({ 'enable': 0, 'time': 0 });

  const [showLoader, setShowLoader] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedDispositions, setSelectedDispositions] = useState(null);
  const [selectedTag, setSelectedTags] = useState([]);
  const [remarks, setRemarks] = useState(null);
  const [callback, setCallback] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [phoneUsed, setPhoneUsed] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCampId, setAllCampId] = useState(false);
  const [assignTo, setAssignTo] = useState("");
  // const [Campaigns, setCampaigns] = useState([]);
  const [totalRowCount, settotalRowCount] = React.useState(0);
  const [Query, setQuery] = useState("");
  const [timeLeft, setTimeLeft] = useState(10);
  const Ref = useRef(null);
  const PhoneRef = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00:00');
  const [enableAutoCalling, setEnableAutoCalling] = useState(false);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }


  const startTimer = (e) => {
    let { total, hours, minutes, seconds }
      = getTimeRemaining(e);
    if (!autoCalling.enable) {
      return;
    }
    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer(
        (seconds > 9 ? seconds : '0' + seconds)
      )
    } else {
      clearInterval(Ref.current);

      // trigger call
      if (PhoneRef.current) {
        if (window.localStorage.getItem("autoCalled") == null) {
          PhoneRef.current.click();
          window.localStorage.setItem("autoCalled", "1");
        }
      }

      //  localStorage.setItem("autoCallTriggered", true)
      //clearTimer(getDeadTime(10));
      //  setCookie('autoCallTriggereds'+autoCalling.campId, true, 1);
    }
  }

  const clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    setTimer('00');


    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) {
      clearInterval(Ref.current);
    }
    const id = setInterval(() => {

      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();


    // This is where you need to adjust if
    // you entend to add more time

    deadline.setSeconds(deadline.getSeconds() + autoCalling.time * 1);
    return deadline;
  }


  const loadCustomFields = async () => {
    let registerRequest;
    try {
      // if (cacheUtil.get("prospect_fields")) {
      //   registerRequest = cacheUtil.get("prospect_fields");
      // }
      // else {
        registerRequest = await API_POST("action/getdata", {
          root: "prospect_fields",
          con: { admin: user && user.admin },
        });
      //}

      if (registerRequest && registerRequest.status == 200) {
        //cacheUtil.set("prospect_fields", registerRequest, 60 * 1000);

        setCustomFields(registerRequest && registerRequest.data);

        setRepeatFilter(registerRequest.data.filter(v => v.display_Name == 'Repeat'))
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  // const getTemplateDetails = async () => {debugger;
  //   let registerRequest;
  //   try {
  //     registerRequest = await API_POST("action/getdata", {
  //       root: "Configuration",
  //       con: { admin: user.admin },
  //     });

  //     if (registerRequest && registerRequest.status == 200) {debugger;
  //       setSelectedTemplate(registerRequest && registerRequest.data);
  //     }
  //   } catch (error) { }
  // };  

  const loadProspects = async () => {
    setShowLoader(true);
    let registerRequest;

    try {
      // if (cacheUtil.get("ViewProspectData_" + props.match.params.pid)) {
      //   registerRequest = cacheUtil.get("ViewProspectData_" + props.match.params.pid);
      // }
      // else {
        registerRequest = await API_GET(
          "action/ViewProspectData?id=" + props.match.params.pid
        );
      //}

      if (registerRequest && registerRequest.status == 200) {
        //cacheUtil.set("ViewProspectData_" + props.match.params.pid, registerRequest, 5 * 60 * 1000);
        setProspects(registerRequest.data.data);
        setBaseProspects(registerRequest.data.data[0]);
        setAssignTo(user && user.username)
        setDispositions(registerRequest.data.dispositions);
        setCalculatedScore(registerRequest.data.calculatedScore);
        setSelectedTags(registerRequest.data.data[0].tags);
        setCampaignFields(registerRequest.data.formFields);
        setCampaigns(registerRequest.data.campaigns);

        setCallHistory(registerRequest.data.callHistory);//ScheduleHistory, AssignmentHistory, prospectSource

        setScheduleHistory(registerRequest.data.ScheduleHistory);
        setAssignmentHistory(registerRequest.data.AssignmentHistory);
        setprospectSource(registerRequest.data.prospectSource);
        setCallDetails(registerRequest.data.CallDetails);

        //setTimelineData data


        if (registerRequest && registerRequest.data && registerRequest.data.CallDetails && registerRequest.data.CallDetails.length > 0) {
          for (let index = 0; index < registerRequest.data.CallDetails.length; index++) {
            const element = registerRequest.data.CallDetails[index];
            console.log("CallDetails", element);
            const timelineelement = {
              type: 'source',
              title: 'Call Details',
              description: <span>Call by {element.caller || 'Unknown'} to {element.phone || "Unknown"} {' '}
                was {element.CallStatus || "unknown"} as {element.type || "unknown"}  {' '}
                on {element.startDate ? new Date(element.startDate).toLocaleDateString() : "unknown time"} {' '}
                for {element.duration || 0} seconds.

                {element.recording && <audio controls >
                  <source src={element.recording} type="audio/mpeg" />
                  <source src={element.recording} type="audio/wav" />
                  <source src={element.recording} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>}
              </span>,
              createdDate: element.startDate,
              color: '#9c27b0',
            }
            setTimelineData(timelineData => [...timelineData, timelineelement]);
          }
        }
        if (registerRequest && registerRequest.data && registerRequest.data.prospectSource && registerRequest.data.prospectSource.length > 0) {
          for (let index = 0; index < registerRequest.data.prospectSource.length; index++) {
            const element = registerRequest.data.prospectSource[index];
            console.log("prospectSource", element);
            const timelineelement = {
              type: 'source',
              title: 'New Source',
              description: `Prospect source: ${element.source || 'Manual'}`,
              createdDate: element.createdOn,
              color: '#9c27b0',
            }
            setTimelineData(timelineData => [...timelineData, timelineelement]);
          }
        }

        if (registerRequest && registerRequest.data && registerRequest.data.AssignmentHistory && registerRequest.data.AssignmentHistory.length > 0) {
          for (let index = 0; index < registerRequest.data.AssignmentHistory.length; index++) {
            const element = registerRequest.data.AssignmentHistory[index];
            console.log("AssignmentHistory", element);
            const timelineelement = {
              type: 'assignment',
              title: 'Assignment',
              description: `Lead assigned to ${element.assignTo || "unknown"} as ${element.leadType || "unspecified"
                } lead from ${element.leadSource || "unknown source"} by ${element.assignedBy || "unknown assigner"
                } on ${element.createdOn ? window.moment(element.createdOn).format("DD-MM-YYYY HH:mm") : "unknown date"}.`,
              createdDate: element.createdOn,
              color: 'secondary',
            }
            setTimelineData(timelineData => [...timelineData, timelineelement]);
          }
        }

        if (registerRequest && registerRequest.data && registerRequest.data.callHistory && registerRequest.data.callHistory.length > 0) {
          for (let index = 0; index < registerRequest.data.callHistory.length; index++) {
            const element = registerRequest.data.callHistory[index];
            console.log("callHistory", element);
            const timelineelement = {
              type: 'activity',
              title: 'Activity',
              description: <>
                <Accordion style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ padding: '0px' }}
                  >
                    <>
                      {element.callType === "OB"
                        ? "Out-bound call made by " + element.caller
                        : "In-bound received made by " + element.caller}
                      &nbsp; at {window.moment(element.createdOn).fromNow()}
                    </>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '0px' }}>
                    <ul className="actimeline">
                      <li>
                        <b>Disposition</b>
                        <em>
                          {_.pluck(
                            element.dispositionArray,
                            "disposition_value"
                          ).join(" > ")}
                        </em>
                      </li>
                      <li>
                        <b>Campaign</b>
                        <em>
                          {element.campaign &&
                            element.campaign.length > 0 &&
                            element.campaign[0] &&
                            element.campaign[0].name}
                        </em>
                      </li>
                      <li>
                        <b>Phone Used</b>
                        <em>
                          {element.campaign &&
                            element.campaign.length > 0 &&
                            element.campaign[0] &&
                            element.campaign[0].phoneNumberMasking
                            ? maskNumber(element.phone)
                            : element.phone}
                        </em>
                      </li>
                      {element.callback && (
                        <li>
                          <b>Callback</b>
                          <em>
                            {window.moment(element.callback).format("DD-MM-YYYY HH:mm")}
                          </em>
                        </li>
                      )}

                      <li>
                        <b>Remarks</b>
                        <em>{element.remarks}</em>
                      </li>
                      {element.recording && <li >
                        <b>Recording</b>
                        <br />
                        <em>
                          <audio controls>
                            <source src={element.recording} type="audio/amr" />
                          </audio>
                        </em>
                      </li>}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </>,
              createdDate: element.createdOn,
              color: 'primary',
            }
            setTimelineData(timelineData => [...timelineData, timelineelement]);
          }
        }



        if (registerRequest && registerRequest.data && registerRequest.data.campaigns[0] && registerRequest.data.campaigns[0].enableAutoCalling) {
          setEnableAutoCalling(true);
        }

        let disregisterRequest = await API_POST("action/getdata", {
          root: "dispositions",
          con: { admin: user && user.admin,IsActive:{$ne: false} },
          cols: {},
        });

        if (disregisterRequest && disregisterRequest.status == 200) {
          setDispositionsAll(disregisterRequest.data);
        }


        // var templatesDatatemp = [];
        // registerRequest.data.templatesData.forEach(element => {
        //   templatesDatatemp[element._id] = element;
        // });
        // setTemplates(templatesDatatemp);

        let type = localStorage.getItem("callingType");
        if (window.localStorage.getItem("autoCalled") == null) {
          if (registerRequest.data.campaigns[0].enableAutoCalling && ["normal", "fresh", "overdue"].indexOf(type) > -1) {
            setautoCalling({ 'enable': true, 'time': registerRequest.data.campaigns[0].autoCallDuration, 'campId': registerRequest.data.campaigns[0]._id });
          }
        }

      }
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }
  };

  const getChild = async (data, parentId) => {
    let returnVal = [];
    if (data && data.length > 0) {
      let _customFields = data;
      for (let index = 0; index < _customFields.length; index++) {
        const element = _customFields[index];
        if (element.parentId == parentId) {
          element["child"] = await getChild(data, element._id);
          returnVal.push(element);
        }
      }
    }
    return returnVal;
  };

  const setReloadPhone = async () => {
    setCookie('webPhone', '', -1);
  };


  const getCampaign = async () => {


    let registerRequest = await API_POST("action/getdata", {
      root: "campaigns",
      con: { admin: user && user.admin, IsActive: true },
    });

    let campArray = [];
    for (let i = 0; i < registerRequest.data.length; i++) {
      campArray.push(registerRequest.data[i]._id);
    }

    setAllCampId(campArray);

  }

  useEffect(() => {

    if (user && customFields.length == 0) {

      if (props.match.params.type == 3) {

        getCampaign();

      }

      loadCustomFields();
      loadProspects();
    }
    //getTemplateDetails();
  }, [user]);

  useEffect(() => {

    // alert(getCookie('autoCallTriggereds'));

    //setCookie('autoCallTriggereds', '1', 1);

    autocalling();





  }, [autoCalling]);

  const autocalling = async () => {

    //console.log(campaigns);

    if (autoCalling.enable) {

      clearTimer(getDeadTime());

    }

  }
  const onSelectTag = async (item) => {
    console.log("tag", item);
    setSelectedTags(item);
  };
  const onSelectDisposition = async (item) => {
    console.log("Disposition", item);
    setSelectedDispositions(item);
  };
  const onRemarksChange = async (item) => {
    console.log("onRemarksChange", item);
    setRemarks(item);
  };
  const onChangeCallback = async (item) => {
    console.log("onChangeCallback", item);
    setCallback(item);
  };
  const onChangeAppointment = async (item) => {
    console.log("onChangeAppointment", item);
    setAppointment(item);
  };

  const onChangeAssignTo = async (item) => {
    console.log("onChangeAssignTo", item);
    setAssignTo(item.target.value);
  };


  const RemoveAllChecks = (flag) => {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) checkboxes[i].checked = flag;
  };

  const handleSave = async (mode) => {

    setBtnDisable(true);
    debugger;
    if (!selectedDispositions || selectedDispositions.length == 0) {
      //alert("Please select disposition.")

      setNotify({
        open: true,
        message: "Please select disposition.",
        color: "danger",
      });
      setBtnDisable(false);
      return false;
    }

    let isCallback = false;
    let hasChild = true;
    let priority = 0;
    let notifyme = false;
    selectedDispositions.forEach((element) => {
      if (element.action && element.action.length > 0) {
        isCallback = true;
      }
      if (!element.hasChild) {
        hasChild = false;
      }
      if (element.priority && element.priority > priority) {
        priority = element.priority;
      }
      if (element.notifyme) {
        notifyme = true;
      }
    });

    if (isCallback) {

      if (!callback) {
        //alert("Please set callback.")
        setNotify({
          open: true,
          message: "Please set callback.",
          color: "danger",
        });
        setBtnDisable(false);
        return false;
      }
    }
    if (hasChild) {

      //alert("Please set callback.")
      setNotify({
        open: true,
        message: "Please select sub-disposition.",
        color: "danger",
      });
      setBtnDisable(false);
      return false;
    }

    let currentCampaign = campaigns && campaigns.length > 0 && campaigns[0];

    let tagsSelected = false;
    if (selectedTag && selectedTag.length) {
      tagsSelected = true;
    }

    if (baseProspects && baseProspects.tags && baseProspects.tags.length) {
      tagsSelected = true;
    }

    //console.log(baseProspects.tags);
    if (currentCampaign) {

      if (currentCampaign.requiredDisposition && !tagsSelected) {

        setNotify({
          open: true,
          message: "Please Select atleast one Tag.",
          color: "danger",
        });
        setBtnDisable(false);
        return false;
      }

      if (currentCampaign.mandatoryRemarks && !remarks) {
        //alert("Please enter remarks.")

        setNotify({
          open: true,
          message: "Please enter remarks.",
          color: "danger",
        });
        setBtnDisable(false);
        return false;
      }
    }

    let registerRequest;
    try {
      baseProspects.disposition = _.pluck(selectedDispositions, "_id");
      baseProspects.tags = selectedTag;
      baseProspects.callDialed = phoneUsed ? true : false;
      baseProspects.Priority = priority;
      baseProspects.notifyme = notifyme;
      baseProspects.assignTo = assignTo;

      registerRequest = await API_POST("action/SaveProspect", {
        baseProspects,
        selectedDispositions,
        callback,
        remarks,
        appointment,
        phoneUsed,

        user: user && user.username,
      });

      // shift prospect 
      let mesgshow = 'Saved Successfully.';

      let dispositionArrays = dispositionsAll.filter((v) => { return baseProspects.disposition.includes(v._id.toString()) });
      let lastDisp = dispositionArrays.find((v) => { return (v.shiftCampaign && v.shiftCampaign != 'Default') });


      if (lastDisp) {
        let registerRequests = await API_POST("action/getdata", {
          root: "dispositions",
          con: { admin: user && user.admin, shiftCampaign: { $ne: 'Default' }, _id: lastDisp._id },
          cols: {},
        });

        if (registerRequests && registerRequests.status == 200) {
          if (registerRequests && registerRequests.data && registerRequests.data.length) {
            let shiftRecord = registerRequests.data[0];
            // check campaignMembers 
            let requestAPI = await API_POST("action/getdata", {
              root: "campaigns",
              con: { admin: user && user.admin, _id: shiftRecord.shiftCampaign },
              cols: {},
            });

            let isUseraddedInCampaign = requestAPI.data.length ? requestAPI.data[0].campaignMembers.includes(user.username) : false;

            // shift data
            if (isUseraddedInCampaign) {

              await API_POST("action/updateProspectDetails", {
                selectedProspects: [baseProspects],
                body: [{ cid: shiftRecord.shiftCampaign }, { admin: user.admin }],
                updatedType: "update",
                Query: {
                  admin: baseProspects.admin,
                  cid: baseProspects.cid,
                  IsActive: true
                }
              });

              mesgshow = 'Saved and shifted Successfully.';

            } else {
              mesgshow = 'Saved Successfully, unable to shift Prospect  .';
            }

          }
        }
      }




      window.localStorage.removeItem("autoCalled");
      if (registerRequest && registerRequest.status == 200) {
        // alert("Saved.",mesgshow)

        setNotify({
          open: true,
          message: mesgshow,
          color: "success",
        });

        setBtnDisable(false);
        //Move to up
        //window.scrollTo(0, 0);

        await callApiOnsubmit();

        if (!mode) {
          loadCustomFields();
          loadProspects();

          try {
            window.save.performClick(baseProspects);
          } catch (e) { }

          try {
            window.Android.showToast(baseProspects);
          } catch (e) { }
        }
        if (mode != "saveandcontinue") {
          var lastURL = window.localStorage.getItem("LastURL");
          if(lastURL.includes("ListProspects")){
            window.history.go(-1);
          }
          if (!lastURL.hasOwnProperty("viewProspects") && baseProspects.cid) {
            history.push("/admin/CampainMoreInfo/" + baseProspects.cid); 
          }

          try {
            window.saveNContinue.performClick(baseProspects);
          } catch (e) { }

          try {
            window.Android.showToast(baseProspects);
          } catch (e) { }
        }
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }


    setBtnDisable(false);

    return true;
  };



  const getCallsetupData = async (id) => {

    var registerRequest = await API_POST("action/getdata",
      {
        root: 'CallingSetup',
        con: { admin: user && user.admin, _id: id },
        cols: {}
      });

    if (registerRequest && registerRequest.status == 200) {

      return registerRequest.data[0];

    }
    return false;
  }

  const callApiOnsubmit = async () => {
    debugger;

    if (selectedDispositions[selectedDispositions.length - 1].communicationType == 'none') {
      return true;
    }

    let templateId = selectedDispositions[selectedDispositions.length - 1].templateId
    let templateData = null;
    var prospects = baseProspects;
    var registerRequest = await API_POST("action/getdata",
      {
        root: 'Templates',
        con: { admin: user && user.admin, _id: templateId },
        cols: {}
      });

    if (registerRequest && registerRequest.status == 200) {
      templateData = registerRequest && registerRequest.data[0];
    }
    var requestTime = new Date();
    if (templateData && templateId) {


      let body = CreateBody(templateData, prospects);

      let request = {
        email: prospects.email,
        bcc: null,
        SelectedTemplate: templateData,
        bodyContent: body,
        pid: prospects._id,
        phone: prospects.phone,
        PhoneCode: prospects.PhoneCode,
        prospect: prospects,
        user: user
      };

      let response = await sendCommByTemplate(request);


    }


    return true;
  }



  const handleSaveNContinues = async () => {



    setBtnDisable(true);



    let result = await handleSave("saveandcontinue");

    if (result) {

      let type = localStorage.getItem("callingType");
      StartCall(type);
      //await callApiOnsubmit();
      clearTimer(getDeadTime());
    }
    setBtnDisable(false);


  };

  const handleCancel = async () => {
    console.log(campaigns);
    window.localStorage.removeItem("autoCalled");

    var lastURL = window.localStorage.getItem("LastURL");
    if (!lastURL.hasOwnProperty("viewProspects")) {
      window.history.go(-1);
      return;
    }

    if (campaigns && campaigns.length > 0) {
      let c = campaigns[0];
      history.push("/admin/CampainMoreInfo/" + c._id);
    }
  };

  const StartCall = async (type) => {
    let cid = null;
    console.log(campaigns);


    if (campaigns && campaigns.length > 0) {
      cid = campaigns[0]._id;
    }
    if (props.match.params.type == 3) {

      type = 'overdueOld';
      //  cid=allCampId;
    }

    let registerRequest;
    try {
      registerRequest = await API_POST("action/StartCall", {
        cid,
        username: user && user.username,
        type: type,
        cidGroup: allCampId ? allCampId : false
      });
      if (registerRequest && registerRequest.status == 200) {
        console.log(registerRequest.data);
        if (registerRequest.data && registerRequest.data.data) {
          let prospect = registerRequest.data.data;
          window.localStorage.removeItem("autoCalled");
          history.push("/admin/ViewProspect/" + prospect._id + "/" + type);
        } else {
          setBtnDisable(false);
          setNotify({
            open: true,
            message: "No Prospect found to call.",
            color: "danger",
          });
          setTimeout(function () {
            handleCancel();
          }, 1000);

          //Redirect to previous url if no prospect found
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDisposition = async (dispositions) => {
    let d = _.pluck(dispositions, "disposition_value");
    return d.join(" > ");
  };

  const CallingAPI = async (prospect, currentCampaign, phone) => {
    debugger;

    console.log(prospect, currentCampaign, phone)
    let requestTime;
    let callingAPIRes;
    let api;
    let registerRequest = await API_POST("action/getdata", {
      root: "CallingSetup",
      con: { _id: currentCampaign.telephony },
      cols: {},
    });

    if (registerRequest && registerRequest.status == 200) {

      //console.log(registerRequest);
      if (registerRequest && registerRequest.data) {

        let callingSetup = registerRequest.data[0];
        api = callingSetup.api;

        api = replaceAndReadyAPI(api, prospect, user, phone);

        requestTime = new Date();


        if (!callingSetup.request_type || callingSetup.request_type == 'get') {

          //callingAPIRes = await API_GET(api);
          let response = await API_POST("comm/CallAPI", {
            url: api, ssl:true,
          });

          callingAPIRes = response.data;
          

          setNotify({
            open: true,
            message: "Call is initiated",
            color: "success",
          });

        } else {


          let msisdnlist = [{
            "phoneno": phone,
            "agentno": user.phone,
            "TrackerID": 123456,
            "admin": callingSetup.admin
          }]
          // console.log("bbbbbbbbbbbbbbbb",{ ...callingSetup,msisdnlist:msisdnlist });
          //   let callingAPIRes = await API_POST(api, {
          //   body: { ...callingSetup,msisdnlist:msisdnlist },
          // });

          let response = await API_POST("comm/postRequest", {
            url: api, body: { ...callingSetup, msisdnlist: msisdnlist }
          });

          callingAPIRes = response.data;

          setNotify({
            open: true,
            message: response.data.error_code ? response.data.message : "Call is initiated",
            color: response.data.error_code ? "danger" : "success",
          });



        }

        let message = [];
        // remove message
        /*      message.push(<p>Calling Setup: {callingSetup.name}</p>);
              message.push(<p>API: {api}</p>);
              message.push(<p>Phone: {phone}</p>);
              message.push(<p>API Response: {JSON.stringify(callingAPIRes && callingAPIRes.data)}</p>);
      */

        /*      if(callingAPIRes.status=='SUCCESS'){
                setNotify({
                  open: true,
                  message: "Call is initiated",
                  color: "success",
                });
              }else{
                setNotify({
                  open: true,
                  message: "Check with service provider",
                  color: "danger",
                });
              }*/



      }

      let APILog = {
        method: 'CallingAPI',
        requestURL: api,
        requestPayload: {},
        requestTime,
        responseData: callingAPIRes,
        responseTime: new Date(),
        trackingId: prospect._id,
        user,
        admin: prospect.admin
      }

      await API_POST("action/addRecord", {
        root: "APILogs",
        body: APILog,
      });


    }

  }

  return (

    <>

      {IsAssignment && (
        <ProspectAssignment
          IsAssignment={IsAssignment}
          handleClose={() => {
            setIsAssignment(false);
          }}
          onChange={() => {
            //  loadData();
          }}
          selectedRows={selectedRows}
          Query={Query}
          totalRowCount={totalRowCount}
          user={user}
          cid={baseProspects.cid}
          RemoveAllChecks={RemoveAllChecks}
          removeButtons
        />
      )}

      <GridContainer>
        <Snackbar
          place="tr"
          color={notify.color}
          message={notify.message}
          open={notify.open}
          closeNotification={() => setNotify({ open: false })}
          close
        />

        {/* <Box mt={2} mr={1} ml="auto" mb={-2}>
          <Button
            color
            size="sm"
            color="primary"
            title="Assign To"
            onClick={() => {
              setIsAssignment(true);
            }}
          >
            Assign to
          </Button>
        </Box> */}

        <GridItem xs={12} sm={12} md={12} style={{ marginTop: '1rem' }}>
          {/* <Button
          size="sm"
          title="Save"
          color="info"
          onClick={handleSave}
        >
          Save
              
        </Button>

        <Button
          size="sm"
          title="Save & Continue"
          color="info"
          onClick={handleSaveNContinues}
        >
          Save & Continue
              
        </Button>

        <Button
          size="sm"
          title="Cancel"
          color="info"
          onClick={handleCancel}
        
        >
          Cancel
              
        </Button> */}

          {campaigns && campaigns.length &&
            <ViewTag
              user={user}
              tags={baseProspects && baseProspects.tags}
              onSelectTag={(item) => onSelectTag(item)}
              closeTagsPermission={campaigns && campaigns.length && campaigns[0]['closeTagsPermission']}

            />

          }
          <GridItem style={{ float: "right", marginTop: "10px" }}>
            <InfoBox name={'Campaigns.ViewProspect'} />
          </GridItem>
        </GridItem>

        {prospects && prospects.length === 0 && (
          <GridItem xs={12} sm={4} md={4}>
            <Card className="CallingCard">
              <CardHeader>
                <h4>Loading Info ...</h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <Loader />
                </GridContainer>
              </CardBody>
              <CardFooter />
            </Card>
          </GridItem>
        )}

        {prospects.map((pros) => {
          let name = "";
          let currentCampaign =
            campaigns && campaigns.length > 0 && campaigns[0];
          if (pros.firstName && pros.lastName) {
            name = pros.firstName + " " + pros.lastName;
          } else if (pros.firstName) {
            name = pros.firstName;
          } else {
            name =
              currentCampaign && currentCampaign.phoneNumberMasking
                ? maskNumber(pros.phone)
                : pros.phone;
          }
          let cardbgcolor = {};
          if (pros._id == props.match.params.pid) {
            cardbgcolor = { backgroundColor: "#5bb15b" };
          }

          return (
            <GridItem xs={12} sm={4} md={4}>
              <Card className="CallingCard">
                <Box bgcolor="#dcd6f7">
                  <CardHeader>
                    <h4>
                      {name}
                    </h4>


                  </CardHeader>

                </Box>
                <Box>
                  <div style={{ display: "inline" }}>
                    {

                      (repeatFilter.length == 0 || (repeatFilter.length && repeatFilter[0].inTabView)) &&
                        baseProspects && baseProspects.updatedCount ? (
                        <>
                          <Badge badgeContent={baseProspects.updatedCount} color="primary">
                            <IconButton>
                              <a href={"/admin/ProspectHistory?phone=" + baseProspects.phone + '&repeat=true'}>
                                <AddToPhotosIcon />
                              </a>
                            </IconButton>

                            {/* <a style={{
        background: "gray",
        padding
      }} href={"/admin/ProspectHistory?phone=" + baseProspects.phone + '&repeat=true'}>
        R
      </a> */}
                          </Badge>
                        </>
                      ) : null
                    }

                    <IconButton
                      style={{ display: "none" }}
                      size="medium"
                      onClick={() => setReloadPhone()}
                    >
                      <i className="fa fa-mobile" />
                    </IconButton>
                    {
                      currentCampaign && currentCampaign.enable_template_email != false ? (
                        <>
                          <TemplatePopup
                            user={user}
                            pid={baseProspects && baseProspects._id}
                            prospects={baseProspects}
                            type="email"
                          />
                        </>
                      ) : null

                    }

                    {
                      currentCampaign && currentCampaign.enable_template_whatsapp != false ? (
                        <>

                          <TemplatePopup
                            user={user}
                            pid={baseProspects && baseProspects._id}
                            prospects={baseProspects}
                            type="whatsapp"
                          />
                        </>
                      ) : null

                    }
                    {
                      currentCampaign && currentCampaign.enable_template_sms != false ? (
                        <>
                          <TemplatePopup
                            user={user}
                            pid={baseProspects && baseProspects._id}
                            prospects={baseProspects}
                            type="sms"
                          />

                        </>
                      ) : null

                    }




                    <IconButton
                      size="medium"
                      onClick={() => { setShowEdit(!showEdit); }}
                    >
                      <i className="fa fa-pencil" />
                    </IconButton>
                    {/* {
                        calculatedScore &&
                        <Badge badgeContent={calculatedScore.finalScore} color="primary">

                        
                      } */}
                  </div>
                </Box>
                <Box>
                    <LeadScorePopup calculatedScore={calculatedScore}/>
                </Box>
                {autoCalling.enable == true && <Box
                  style={{ 'background': '#02acc2', 'padding': '10px', 'margin': '0px', 'font-size': '19px' }}>
                  Call get auto-dialed in: <span style={{ 'color': 'red', 'font-size': '24px' }}>   {timer}   </span> seconds
                </Box>}
                <>

                  <GridContainer>





                    <ul>
                      {/* <li>
                      <b>Phone</b>  <em><a href={'tel:' + pros.phone}>{pros.phone}</a></em>
                    </li> */}
                      {customFields.map((item) => {
                        let currentCampaign =
                          campaigns && campaigns.length > 0 && campaigns[0];
                        if (item.inTabView && pros[item.field_name]) {
                          if (item.isPhone || item.field_type == "phone") {
                            return (
                              <li
                                style={{
                                  padding: "0px 4px",
                                  margin: "1px 0px",
                                }}
                              >
                                <b>{item.display_Name}</b>
                                <em className="phone" style={{
                                  display: 'inline'
                                }}>

                                  {currentCampaign &&
                                    currentCampaign.telephony !=
                                    "Default" ? (
                                    <a ref={PhoneRef}
                                      onClick={() => {
                                        setPhoneUsed(pros[item.field_name])
                                        CallingAPI(
                                          pros,
                                          currentCampaign,
                                          pros[item.field_name]
                                        )
                                      }
                                      }
                                    >
                                      {currentCampaign &&
                                        currentCampaign.phoneNumberMasking
                                        ? maskNumber(pros[item.field_name])
                                        : pros[item.field_name]}
                                    </a>
                                  ) : (
                                    <a ref={PhoneRef}
                                      onClick={() =>
                                        setPhoneUsed(pros[item.field_name])
                                      }
                                      href={
                                        pros.PhoneCode == 91
                                          ? "tel:0" + pros[item.field_name]
                                          : "tel:" + pros[item.field_name]
                                      }
                                    >
                                      {currentCampaign &&
                                        currentCampaign.phoneNumberMasking
                                        ? maskNumber(pros[item.field_name])
                                        : pros[item.field_name]}
                                    </a>
                                  )}

                                  &nbsp;

                                  <WhatsApp PhoneCode={pros.PhoneCode} Phone={pros[item.field_name]}></WhatsApp>
                                  {/* <a
                                    href={
                                      "https://wa.me/" +
                                      (pros.PhoneCode == 91 ? "91" : "") +
                                      (pros[item.field_name] ? pros[item.field_name].toString().replace("+", "") : "")
                                    }
                                    target="_blank"
                                    className="whatsapp"
                                  >
                                    <i className="fa fa-whatsapp" />
                                  </a> */}
                                </em>
                              </li>
                            );
                          } else if (item.isEmail) {
                            return (
                              <li
                                style={{
                                  padding: "0px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <b>{item.display_Name}</b>{" "}
                                {pros[item.field_name] && (
                                  <em className="email">
                                    <a href={"email:" + pros[item.field_name]}>
                                      {pros[item.field_name]}
                                    </a>
                                  </em>
                                )}
                              </li>
                            );
                          } else {
                            return (
                              <li
                                style={{
                                  padding: "0px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <b>
                                  {String(item.display_Name)
                                    .charAt(0)
                                    .toUpperCase() +
                                    String(item.display_Name).slice(1)}
                                </b>{" "}
                                <em> {(item.display_Name === 'callback' || item.display_Name === 'createdOn') ? window.moment(pros[item.field_name]).format("DD-MM-YYYY HH:mm") : pros[item.field_name]}</em>
                              </li>
                            );

                          }
                        }
                      })}
                    </ul>
                    <GridItem xs={12} sm={12} md={12}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h4>More Details</h4>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul>
                            {customFields.map((item) => {

                              if (!item.inTabView && pros[item.field_name])
                                if (item.isPhone) {
                                  return (
                                    <li>
                                      <b>{item.display_Name}</b>{" "}
                                      <em className="phone" >
                                        <a style={{ fontSize: "14px" }}
                                          href={
                                            pros.PhoneCode == 91
                                              ? "tel:0" +
                                              pros[item.field_name]
                                              : "tel:" + pros[item.field_name]
                                          }
                                        >
                                          {pros[item.field_name]}
                                        </a>
                                        &nbsp;&nbsp;

                                        <WhatsApp PhoneCode={pros.PhoneCode} Phone={pros[item.field_name]}></WhatsApp>
                                        {/* <a style={{ fontSize: "14px" }}
                                          href={
                                            "https://wa.me/" +
                                            (pros.PhoneCode == 91 ? "91" : "") +
                                            (pros[item.field_name] ? pros[item.field_name].toString().replace("+", "") : "")
                                          }
                                          target="_blank"
                                        >
                                          <i className="fa fa-whatsapp" />
                                        </a> */}
                                      </em>
                                    </li>
                                  );
                                } else if (item.isEmail) {
                                  return (
                                    <li>
                                      <b>{item.display_Name}</b>{" "}
                                      <em className="email">
                                        <a
                                          href={
                                            "email:" + pros[item.field_name]
                                          }
                                        >
                                          {pros[item.field_name]}
                                        </a>
                                      </em>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li>
                                      <b>{item.display_Name}</b>{""}
                                      <em>{!Array.isArray(pros[item.field_name]) ? formatFieldValue(pros[item.field_name]) : ""}</em>
                                    </li>
                                  );
                                }
                            })}
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </GridItem>
                  </GridContainer>
                </>
                <>
                  <GridContainer>
                    <ul
                      style={{

                        padding: "3px",
                      }}
                    >
                      {pros.source && (
                        <li>
                          <b>Source</b>
                          <em style={{ textAlign: "right" }}>{pros.source}</em>
                        </li>
                      )}
                      {campaigns && campaigns.length > 0 && (
                        <li>
                          <b>Campaign</b>
                          <em style={{ textAlign: "right" }}>
                            {campaigns &&
                              campaigns.length > 0 &&
                              campaigns[0] &&
                              campaigns[0].name}
                          </em>
                        </li>
                      )}
                      {pros.createdOn && (
                        <li>
                          <b>Lead Created Date</b>
                          <em style={{ textAlign: "right" }}>
                            <a style={{ backgroundColor: '#436fb3' }} href={"/admin/ProspectHistory?phone=" + pros.phone + "&repeat=true"} >
                              <i class="fa fa-history" aria-hidden="true"></i>

                              {/* <img style={{ width: '21%', float: 'inline-end', position: 'relative', top: '-6px' }} src={prospectdup} />   */}

                            </a>

                            {window.moment(pros.createdOn).format("DD-MM-YYYY HH:mm")}

                          </em>
                        </li>
                      )}
                      {pros.revisitedOn && (
                        <li>
                          <b>Last Created Date</b>
                          <em style={{ textAlign: "right" }}>
                            {window.moment(pros.revisitedOn).format("DD-MM-YYYY HH:mm")}
                          </em>
                        </li>
                      )}
                      {pros.updatedOn && (
                        <li>
                          <b>Last Updated Date</b>
                          <em style={{ textAlign: "right" }}>
                            {window
                              .moment(pros.updatedOn)
                              .format("DD-MM-YYYY HH:mm")}
                          </em>
                        </li>
                      )}
                      {pros.dispositionArray && (
                        <li>
                          <b>Last Disposition</b>
                          <em style={{ textAlign: "right" }}>
                            {" "}
                            {_.pluck(
                              pros.dispositionArray,
                              "disposition_value"
                            ).join(" > ")}
                          </em>
                        </li>
                      )}
                      {pros.callback && (
                        <li>
                          <b>Followup Date</b>
                          <em style={{ textAlign: "right" }}>
                            {window
                              .moment(pros.callback)
                              .format("DD-MM-YYYY HH:mm")}
                          </em>
                        </li>
                      )}
                    </ul>
                  </GridContainer>
                </>
              </Card>
            </GridItem>
          );
        })}

        <GridItem xs={12} sm={4} md={4}>
          <ViewDisposition
            dispositions={dispositions}
            campaign={campaigns}
            assignTo={assignTo}
            baseProspects={baseProspects}
            onChangeAssignTo={(item) => onChangeAssignTo(item)}
            //templates={templates}
            className="CallingCard"
            Title="Dispositions"
            user={user}
            showRemarks={true}
            showControls={true}
            onSelectDisposition={(item) => onSelectDisposition(item)}
            onRemarksChange={(item) => onRemarksChange(item)}
            onChangeCallback={(item) => onChangeCallback(item)}
            onChangeAppointment={(item) => onChangeAppointment(item)}
          />

          {props.match.params.type == "1" ? null :
            <Button
              style={{margin:"10px"}}
              size="sm"
              title="Save & Continue"
              color="info"
              disabled={btnDisable}
              onClick={handleSaveNContinues}
            >
              Save & Continue
              {/* <CachedIcon /> */}
            </Button>
          }

          <Button
            size="sm"
            title="Save"
            style={{margin:"10px"}}
            color="info"
            disabled={btnDisable}
            onClick={() => {
              handleSave("Save");
            }}
          >
            Save & Quit
            {/* <EditIcon /> */}
          </Button>






<br/>

          <Button
            size="sm"
            title="Cancel"
            color="info"
            style={{marginLeft:"10px"}}
            onClick={handleCancel}
          // onClick={() => this.handleAdd()}
          >
            Cancel
            {/* <DeleteForeverIcon /> */}
          </Button>
        </GridItem>

        {/* <GridItem xs={12} sm={4} md={4}>

        <Card className="CallingCard">
          <CardHeader>
            <h4>
              Remarks
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <Remarks prospect={prospects && prospects.length > 0 ? prospects[0] : null} user={user}></Remarks>
            </GridContainer>
          </CardBody>
          <CardFooter>
          </CardFooter>
        </Card>
      </GridItem> */}
        <GridItem xs={12} sm={4} md={4}>
          <Card className="CallingCard">
            <Box bgcolor="#dcd6f7">
              <CardHeader>
                <h4>Contact history</h4>
              </CardHeader>
            </Box>
            <CardBody style={{ padding: "0px" }}>
              {showLoader && (
                <GridContainer justify="center">
                  {" "}
                  <Loader />{" "}
                </GridContainer>
              )}
              <GridContainer>
                <TimeLine data={timelineData} />


                {/* {callHistory.map((item) => {
                  return (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p>
                          {item.callType === "OB"
                            ? "Out-bound call made by " + item.caller
                            : "In-bound received made by " + item.caller}
                          &nbsp; at {window.moment(item.createdOn).fromNow()}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul>
                          <li>
                            <b>Disposition</b>
                            <em>
                              {_.pluck(
                                item.dispositionArray,
                                "disposition_value"
                              ).join(" > ")}
                            </em>
                          </li>
                          <li>
                            <b>Campaign</b>
                            <em>
                              {item.campaign &&
                                item.campaign.length > 0 &&
                                item.campaign[0] &&
                                item.campaign[0].name}
                            </em>
                          </li>
                          <li>
                            <b>Phone Used</b>
                            <em>
                              {item.campaign &&
                                item.campaign.length > 0 &&
                                item.campaign[0] &&
                                item.campaign[0].phoneNumberMasking
                                ? maskNumber(item.phone)
                                : item.phone}
                            </em>
                          </li>
                          {item.callback && (
                            <li>
                              <b>Callback</b>
                              <em>
                                {window
                                  .moment(item.callback)
                                  .format("DD-MM-YYYY HH:mm")}
                              </em>
                            </li>
                          )}

                          <li>
                            <b>Remarks</b>
                            <em>{item.remarks}</em>
                          </li>
                          {item.recording && <li >
                            <b>Recording</b>
                            <br />
                            <em>
                              <audio controls>
                                <source src={item.recording} type="audio/amr" />
                              </audio>
                            </em>
                          </li>}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  );
                })} */}
              </GridContainer>
            </CardBody>
            <CardFooter />
          </Card>
        </GridItem>
        {
          (user && user.is_superuser == 1) && (baseProspects && baseProspects.email) &&
          <GridItem xs={12} sm={12} md={12} >
            <CRMView
              admin={baseProspects.email}
            />
            {/* <iframe className="crmurl" src={`http://localhost:3000/crm/CRMView/${baseProspects.email}`} ></iframe> */}

          </GridItem>
        }


        <GridItem xs={12} sm={12} md={12} >

          {showEdit && baseProspects && (
            <EditProspect
              pid={baseProspects._id}
              baseProspects={baseProspects}
              user={user}
              showEdit={setShowEdit}
            />
          )}

        </GridItem>
      </GridContainer>

    </>
  );
}

ViewProspect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ViewProspect);
